/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.html5.js';
import PageHeader from '../../components/PageHeader';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from './styles';
import '../../dtButtons.css';

function getYesterdayDate() {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toISOString().split('T')[0];
}

function getTodayDate() {
  return new Date().toISOString().split('T')[0];
}

// function getDate90DaysAgo() {
//   const today = new Date();
//   today.setDate(today.getDate() - 90);
//   return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
// }

const getParamsFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {};

  params.draw = parseInt(urlParams.get('draw')) || 1;
  params.start = parseInt(urlParams.get('start')) || 0;
  params.length = parseInt(urlParams.get('length')) || 25; // Set default length to 25

  params.dateStart = urlParams.get('dateStart') || getYesterdayDate();
  params.dateEnd = urlParams.get('dateEnd') || getYesterdayDate();

  const order = urlParams.get('order');
  if (order) {
    params.order = JSON.parse(order);
  }

  const search = urlParams.get('search');
  if (search) {
    try {
      const decodedSearch = decodeURIComponent(search);
      params.search = JSON.parse(decodedSearch);
    } catch (e) {
      console.error('Error parsing search parameter:', e);
      params.search = [];
    }
  }

  return params;
};

const ReportsDataTable = ({ exportFunc }) => {
  const classes = useStyles();
  const tableRef = useRef(null);
  const [render, setRender] = useState(false);
  const paramsFromURL = useMemo(() => getParamsFromURL(), []);
  const minDateRef = useRef(paramsFromURL.dateStart || getYesterdayDate());
  const maxDateRef = useRef(paramsFromURL.dateEnd || getYesterdayDate());
  const history = useHistory();

  function styler(input, width) {
    return `<p style="width: ${width}px; padding-left: 1px; padding-right: 1px;">${input}</p>`;
  }

  function getDate90DaysAgo() {
    const today = new Date();
    today.setDate(today.getDate() - 90);
    return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  }

  const columns = useMemo(
    () => [
      {
        title: 'Email Address',
        data: 'email_address',
        defaultContent: '<i>No value</i>',
        orderable: false,
        render: function (data) {
          return styler(data, 225);
        },
      },
      {
        title: 'Pixel ID',
        data: 'uuid',
        defaultContent: '-',
        orderable: false,
        render: function (data) {
          return styler(data, 200);
        },
      },
      {
        title: 'List Name',
        data: 'list_name',
        defaultContent: '<i>No value</i>',
        orderable: false,
        render: function (data) {
          return styler(data, 200);
        },
      },
      {
        title: 'Integration',
        data: 'integration_name',
        defaultContent: '<i>No value</i>',
        orderable: false,
        render: function (data) {
          return styler(data, 200);
        },
      },
      {
        title: 'Status Code',
        data: 'status_code',
        defaultContent: '-',
        orderable: false,
        render: function (data) {
          return styler(data, 150);
        },
      },
      {
        title: 'Response Text',
        data: 'response_text',
        defaultContent: '<i>No value</i>',
        orderable: false,
        render: function (data) {
          return styler(data, 150);
        },
      },
      {
        title: 'Date Created',
        data: 'date_created',
        defaultContent: '<i>No value</i>',
        orderable: false,
        render: function (data) {
          return styler(data, 150);
        },
      },
      {
        title: 'Date Triggered',
        data: 'date_sent',
        defaultContent: '<i>No value</i>',
        orderable: false,
        render: function (data) {
          return styler(data, 150);
        },
      },
    ],
    [],
  );

  const fetchData = async params => {
    let urlParams = new URLSearchParams(window.location.search);

    // Set date range parameters
    params['dateStart'] = minDateRef.current.value || getYesterdayDate();
    params['dateEnd'] = maxDateRef.current.value || getTodayDate();

    // Transform params.columns to include index, data (name), and search value
    const transformedColumns = params.columns.map((column, index) => ({
      index: index,
      name: column.data,
      searchValue: column.search.value,
    }));

    // Serialize the transformedColumns as a JSON string
    urlParams.set(
      'search',
      encodeURIComponent(JSON.stringify(transformedColumns)),
    );

    // Update the URL with the current query string
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;

    window.history.replaceState(null, '', newUrl);

    try {
      console.log('HERE 1', params, transformedColumns);
      // Send the transformed parameters to the server
      const response = await axios.post(
        `/reports/outgoingNotifications/?datatables=yes`,
        { ...params, columns: transformedColumns },
      );

      return response;
    } catch (err) {
      console.log('HERE 2');

      console.error('REQUEST timed out or failed: ', err);
    }
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  useEffect(() => {
    const initializeDataTable = () => {
      if (!$.fn.DataTable.isDataTable(tableRef.current)) {
        const initialParams = getParamsFromURL();

        const table = $(tableRef.current).DataTable({
          serverSide: true,
          processing: true,
          columns: columns,
          dom: '<"d-flex align-items-center dt-controls" l <"date-range-inputs"> B>rtip',
          responsive: true,
          scrollX: true,
          pageLength: initialParams.length || 25, // Set default length to 25
          order: initialParams.order || [[7, 'desc']],
          search: initialParams.search || {
            value: '',
            regex: false,
            return: true,
          },
          ajax: (data, callback, settings) => {
            fetchData(data)
              .then(result => {
                console.log('RESULT: ', result);
                callback({
                  draw: data.draw,
                  recordsTotal: result.recordsTotal || result.length,
                  recordsFiltered: result.recordsFiltered || result.length,
                  data: result.data,
                });
              })
              .catch(error => {
                console.error('Error fetching data: ', error);
                callback({
                  draw: data.draw,
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: [],
                });
              });
          },
          buttons: [
            {
              extend: 'csv',
              text: 'Export',
              action: function (e, dt, node, config) {
                fetchAllDataAndExport();
              },
            },
          ],
          language: {
            infoFiltered: '',
            // processing: ReactDOMServer.renderToString(<CircularProgress />),
          },
          initComplete: function (settings, json) {
            const api = this.api();
            applyCustomStyles(api);

            // Iterate over each column and apply the corresponding search value
            api.columns().every(function (index) {
              const column = this;

              // Create search input with debounce
              const wrapper = $(
                '<div class="column-search-wrapper" style="margin-top: 5px; width: 90%; margin-left: -10px;"></div>',
              );
              const searchInput = $(
                '<input type="text" class="column-search" placeholder="Search" style="width: 100%; box-sizing: border-box; padding: 4px;"/>',
              ).on(
                'keyup change clear',
                debounce(function (e) {
                  e.stopPropagation();
                  const searchValue = this.value;
                  if (column.search() !== searchValue) {
                    column.search(searchValue).draw();
                  }
                }, 500),
              ); // Adjust debounce time as needed

              wrapper.append(searchInput);
              $(column.header()).append(wrapper);

              // Apply the search value from decoded URL to the search input and filter the data
              if (initialParams.search && initialParams.search.length > 0) {
                const searchEntry = initialParams.search.find(
                  entry => entry.index === index,
                );
                if (searchEntry && searchEntry.searchValue) {
                  searchInput.val(searchEntry.searchValue); // Set the search input's value
                  column.search(searchEntry.searchValue); // Set the DataTable's column search value
                }
              }
            });

            // Redraw the table after applying all search filters
            api.draw();

            $('.date-range-inputs').html(
              `<label class="date-range-input">From: <input type="date" id="min" name="min" min="${getDate90DaysAgo()}" max="${getTodayDate()}" value="${
                initialParams.dateStart
              }"></label> &nbsp; ` +
                `<label class="date-range-input">To: <input type="date" id="max" name="max" min="${getDate90DaysAgo()}" max="${getTodayDate()}" value="${
                  initialParams.dateEnd
                }"></label>`,
            );

            minDateRef.current = document.getElementById('min');
            maxDateRef.current = document.getElementById('max');

            // Create a container for the error message above the date range inputs
            // Create an error container above the date range inputs
            if (!$('.date-range-error').length) {
              $('.d-flex.align-items-center.dt-controls').before(
                `<div class="date-range-error" style="color: red; font-size: 14px;
                  font-weight: bold; margin-bottom: 5px; text-align: left; display: none;">
                </div>`,
              );
            }

            if (!$('.date-range-error').length) {
              $(api.table().container()).find('.pagination').css({
                display: 'none',
              });
            }

            $(api.table().container()).find('.dt-column-order').css({
              display: 'none',
            });

            $('#min, #max').on('change', function () {
              const minDate = minDateRef.current.value;
              const maxDate = maxDateRef.current.value;
              const errorContainer = $('.date-range-error');

              // Check if the min date is greater than the max date
              if (minDate && maxDate && new Date(minDate) > new Date(maxDate)) {
                // Display the error message above the date inputs
                errorContainer
                  .text('Start date cannot be greater than End date.')
                  .show();
              } else {
                // Hide the error message if the dates are valid
                errorContainer.hide();

                // Redraw the table if the dates are valid
                const api = $(tableRef.current).DataTable();
                api.draw();
              }
            });

            // Apply styles immediately after the DOM is updated
            setTimeout(() => {
              applyCustomStyles(api);
            }, 0);
          },
          drawCallback: function (settings) {
            const api = this.api();
            applyCustomStyles(api);

            // Get pagination information
            const pageInfo = api.page.info();

            // Check if the last page is NaN or if there are no pages
            if (isNaN(pageInfo.pages) || pageInfo.pages === 0) {
              $(api.table().container())
                .find('.pagination')
                .css('display', 'none');
            } else {
              $(api.table().container())
                .find('.pagination')
                .css('display', 'flex'); // Or your desired style
            }

            const draw = settings.iDraw || api.context[0]._iDraw;
            const start = settings._iDisplayStart;
            const length = settings._iDisplayLength;
            const order = api.order();

            // Collect column search values
            const searchColumns = api
              .columns()
              .data()
              .toArray()
              .map((col, index) => {
                const searchValue = api.column(index).search();
                return {
                  index: index,
                  name: col.sName || col.name, // Depending on how you define columns
                  searchValue: searchValue,
                };
              });

            // Ensure the date parameters are updated from the refs or fallback to defaults
            const dateStart = minDateRef.current?.value || getYesterdayDate();
            const dateEnd = maxDateRef.current?.value || getYesterdayDate();

            // Serialize parameters for the URL
            const queryString = new URLSearchParams({
              draw: draw,
              start: start,
              length: length,
              order: JSON.stringify(order),
              search: JSON.stringify(searchColumns), // Use column search data
              dateStart: dateStart,
              dateEnd: dateEnd,
            }).toString();

            // Update the URL with the new query string
            const newUrl = `${window.location.pathname}?${queryString}`;
            window.history.replaceState(null, '', newUrl);

            // Hide pagination if there are no results
            const recordsTotal = settings._iRecordsDisplay;
            if (!recordsTotal) {
              $(api.table().container()).find('ul.pagination').hide();
            } else {
              $(api.table().container()).find('ul.pagination').show();
            }
          },
        });

        if (paramsFromURL.search && paramsFromURL.search.value) {
          table.search(paramsFromURL.search.value).draw();
        }
      }
    };

    initializeDataTable();

    window.addEventListener('resize', initializeDataTable);

    return () => {
      window.removeEventListener('resize', initializeDataTable);
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [render]);

  async function fetchAllDataAndExport() {
    const table = $(tableRef.current).DataTable();
    const params = table.ajax.params();

    // Collect column search values to include in the export
    const searchColumns = table
      .columns()
      .data()
      .toArray()
      .map((col, index) => {
        const searchValue = table.column(index).search();
        return {
          index: index,
          name: col.sName || col.name, // Adjust based on your column definition
          searchValue: searchValue,
        };
      });

    // Add the search columns to the params
    params.columns = searchColumns;

    try {
      console.log('PARAMS: ', params);
      const response = await axios.post(
        '/reports/outgoingNotifications/?datatables=yes&export=yes',
        params,
      );

      history.push('/reports/all');
    } catch (error) {
      console.error('Error fetching data for export:', error);
    }
  }

  function applyCustomStyles(api) {
    $(api.table().container()).find('.makeStyles-paper-35').css({
      'max-width': '1200px',
    });
    $(api.table().container())
      .find('label, input, select, th, td, .dt-info')
      .css({
        'font-size': '14px',
        'font-weight': 'bold',
      });
    $(api.table().container()).find('label').css({
      padding: '5px',
    });
    $(api.table().container()).find('input, select').css({
      margin: '10px',
    });
    $(api.table().container()).find('.date-rage-input').css({
      padding: '8px',
    });
    $(api.table().container()).find('thead tr th').css({
      'padding-left': '10px',
      'text-align': 'left',
      'border-bottom': '2px solid #dee2e6',
      'border-right': '1px solid #dee2e6',
    });

    $(api.table().container()).find('tfoot tr th').css({
      'border-bottom': '2px solid #dee2e6',
    });

    $(api.table().container()).find('.dt-info').css({
      'padding-left': '10px',
    });
    $(api.table().container()).find('tbody tr td').css({
      'padding-left': '5px',
    });

    $(api.table().container())
      .find('tbody tr td:last-child, thead tr th:last-child')
      .css({
        'border-right': 'none',
      });

    $(api.table().container())
      .find(
        '.DTFC_LeftWrapper table th, .DTFC_LeftWrapper table td, .DTFC_Cloned th, .DTFC_Cloned td',
      )
      .css({
        'border-right': '1px solid #dee2e6',
        'background-color': 'inherit',
      });

    $(api.table().container())
      .find(
        '.DTFC_LeftWrapper table th:last-child, .DTFC_LeftWrapper table td:last-child, .DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child',
      )
      .css({
        'border-right': 'none',
      });
    $(api.table().container())
      .find('.DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child')
      .css({
        'box-shadow': '10px 0 5px -2px rgba(0, 0, 0, 0.3)',
      });

    // Apply custom styles for pagination controls
    $(api.table().container()).find('ul.pagination').css({
      display: 'flex', // Use flexbox to align items horizontally
      justifyContent: 'center', // Center align pagination controls
      padding: '0', // Remove default padding
      marginTop: '10px', // Add some spacing above the pagination controls
      listStyle: 'none', // Remove default list styling
    });

    $(api.table().container()).find('.date-range-inputs').css({
      'font-size': '14px',
      'font-weight': 'bold',
    });

    $(api.table().container()).find('ul.pagination li').css({
      display: 'inline',
      margin: '0 2px', // Add spacing between pagination items
    });

    $(api.table().container()).find('ul.pagination li a').css({
      display: 'inline-block', // Ensure links are inline
      padding: '5px 10px', // Add padding for clickable area
      border: '1px solid #ddd', // Border for pagination items
      borderRadius: '5px', // Rounded corners for pagination items
      textDecoration: 'none', // Remove underline from links
      color: '#333', // Text color
    });

    $(api.table().container()).find('ul.pagination li.active a').css({
      backgroundColor: '#E00001', // Highlight background color for the active page
      color: 'white', // Text color for active page
    });

    $(api.table().container()).find('ul.pagination li a:hover').css({
      backgroundColor: '#f1f1f1', // Background color on hover
      color: '#333', // Text color on hover
    });

    $(api.table().container()).find('.dt-controls').css({
      display: 'flex',
      alignItems: 'center',
    });

    // Increase size for all inputs with ids matching dt-search-*
    $(api.table().container()).find('input[id^="dt-search-"]').css({
      height: '2.5rem',
      width: '15rem',
    });

    // Increase size for all selects with ids matching dt-length-*
    $(api.table().container()).find('select[id^="dt-length-"]').css({
      height: '2.5rem',
      width: '3rem',
    });

    // Apply flexbox to div.dt-length
    $(api.table().container()).find('div.dt-length').css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    });

    $(api.table().container()).find('div.dt-search').css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    });

    $(api.table().container()).find('.buttons-html5, .buttons-print').css({
      'background-color': '#4CAF50',
      'border-width': '0',
      'border-radius': '5px',
      color: 'white',
    });

    $(api.table().container())
      .find('input[id^="dt-search-"]')
      .first()
      .each(function () {
        if (!$(this).next('.search-instruction').length) {
          $(this).after(
            '<span class="search-instruction">Press enter to search</span>',
          );
        }
      });

    $(api.table().container()).find('.search-instruction').css({
      'font-size': '12px',
      'font-weight': 'normal',
      'margin-left': '12px',
      'margin-right': '12px',
      color: '#888',
    });
  }

  return (
    <>
      <PageHeader title="Trigger Reports" />
      <Paper className={classes.paper}>
        <Box>
          <table
            ref={tableRef}
            className="display"
            style={{ width: '100%' }}
          ></table>
        </Box>
      </Paper>
    </>
  );
};

export default ReportsDataTable;
