/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// React
import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
// Datatables
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css'; // DataTables Buttons Bootstrap 4 CSS
import 'datatables.net-bs4'; // DataTables core functionality
import 'datatables.net-buttons-bs4'; // DataTables Buttons functionality
import 'datatables.net-buttons/js/dataTables.buttons.min.js'; // Updated import for buttons
import 'datatables.net-buttons/js/buttons.colVis.min.js'; // Column visibility button functionality
import CallBack from './callback';
// MUI
import Box from '@material-ui/core/Box';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
// Components
import Spinner from '../../components/Spinner';
import DeleteIcon from './delete.svg';
import EditIcon from './edit.svg';
import '../../dtButtons.css';
import Chart from 'chart.js/auto';

const TriggerList = ({
  rows,
  isLoading,
  totalPages,
  totalElements,
  onQueryChange,
}) => {
  const tableRef = useRef(null);
  const [triggerDel, setTriggerDel] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [delPass, setDelPass] = useState(false);
  const [delFail, setDelFail] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);

  const colors = {
    0: '#000000', // Black
    3: '#FFFF00', // Yellow
    200: '#0000FF', // Blue
    201: '#008000', // Green
    202: '#008080', // Teal
    203: '#800080', // Purple
    204: '#A52A2A', // Brown
    408: '#FFA500', // Orange
    500: '#E00001', // Red
  };

  const styler = (width, input) => {
    return `<p style="width: ${width}px;">${input}</p>`;
  };

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        data: 'id',
        width: '5px',
        render: function (data) {
          return styler(5, data);
        },
      },
      {
        title: 'Name',
        data: 'name',
        width: '150px',
        render: function (data) {
          return styler(150, data);
        },
      },
      // { title: 'Description', data: 'description' },
      {
        title: 'Associated Lists',
        data: 'associated_lists',
        width: '150px',
        render: function (data, type, row) {
          let currWidth = '150px';
          if (data && data.length) {
            const uniqueListIds = new Set();
            const filteredLists = data.filter(item => {
              // Ensure item.list_id is not null or undefined before adding to the set
              if (item.list_id && !uniqueListIds.has(item.list_id)) {
                uniqueListIds.add(item.list_id);
                return true; // Include this item
              }
              return false; // Exclude duplicates or null/undefined list_id
            });

            // If there's only one list, display it as usual
            if (filteredLists.length === 1) {
              return filteredLists
                .map(al => {
                  return `<p style="width: ${currWidth};"><a class="associated-lists" href="/list-manager/${al.list_id}/edit">${al.list_name}</a></p>`;
                })
                .join('');
            } else if (filteredLists.length > 1) {
              // Sort lists by list_id in descending order and get the list with the highest id
              const sortedLists = filteredLists.sort(
                (a, b) => b.list_id - a.list_id,
              );
              const highestList = sortedLists[0]; // List with the highest id
              const remainingCount = filteredLists.length - 1; // Calculate remaining lists
              const allListNames = filteredLists
                .map(al => al.list_name)
                .join(', ');

              return `<p style="width: ${currWidth};">
                        <a class="associated-lists" href="/list-manager/${
                          highestList.list_id
                        }/edit" title="${allListNames}">
                          ${highestList.list_name}${' '}
                        </a>
                        and ${remainingCount} more...
                      </p>`;
            }
          }
          // If data is null or empty, display 'N/A'
          return 'N/A';
        },
      },
      // { title: 'Integration', data: 'integration_name', width: '200px' },
      {
        title: 'Integration',
        data: 'integration',
        width: '200px',
        render: function (data) {
          if (data && data[0]?.integrations_id) {
            const uniqueListIds = new Set();
            return data
              .filter(item => {
                if (!uniqueListIds.has(item.integrations_id)) {
                  uniqueListIds.add(item.integrations_id);
                  return true; // Include this item
                }
                return false; // Exclude duplicates
              })
              .map(al => {
                return `<p style="width: 200px;"><a className="associated-lists" href="/integrations/${al.integrations_id}/edit">${al.integrations_name}</a></p>`;
              })
              .join('');
          } else {
            return '-';
          }
        },
      },
      {
        title: 'Responses Over 7 Days',
        data: 'status_codes',
        orderable: false,
        width: '225px',
        render: function (data, type, row) {
          const chartId = `chart-${row.id}`;
          return `<div class="chart-details" style="width:225px; height:100px;"><canvas id="${chartId}"></canvas></div>`;
        },
      },
      {
        title: 'Trigger Insertions Today',
        data: 'count',
        width: '80px',
        render: function (data) {
          return styler(80, data ? data : 0);
        },
      },
      {
        title: 'Status',
        data: 'status',
        width: '100px',
        render: function (data) {
          if (data === 17) {
            return `<span style='border-radius: 5px; padding: 5px; background-color: rgb(30, 146, 244); color: rgb(235 246 255); border: none;'>System Paused</span>`;
          } else if (data === 1) {
            return `<span style='border-radius: 5px; padding: 5px; background-color: rgb(196, 248, 226); color: rgb(6, 165, 97); border: none;'>Active</span>`;
          } else {
            return `<span style='border-radius: 5px; padding: 5px; background-color: rgb(255, 244, 201); color: rgb(249, 150, 0); border: none;'>Inactive</span>`;
          }
        },
      },
      {
        title: 'Date Created',
        data: 'date_created',
        width: '150px',
        render: data => styler(150, formatToMMDDYYYY(data)),
      },
      {
        title: 'Last Updated',
        data: 'date_updated',
        width: '150px',
        render: data => styler(150, formatToMMDDYYYY(data)),
      },
      //////////////////////////////////////////////////////
      {
        title: 'Daily Trigger Limit',
        data: 'daily_trigger_limit',
        width: '75px',
        render: data => styler(75, data),
      },
      {
        title: 'Pause Code Value',
        data: 'pause_code_value',
        width: '75px',
        render: data => styler(75, data),
      },
      {
        title: 'Deactivate Code Value',
        data: 'deactivate_code_value',
        width: '75px',
        render: data => styler(75, data),
      },
      {
        title: 'Description',
        data: 'description',
        width: '200px',
        render: data => styler(200, data ? data : 'N/A'),
      },
      {
        title: 'One Time Per User',
        data: 'one_time_per_user',
        width: '150px',
        render: data => styler(150, data ? 'True' : 'False'),
      },
      {
        title: 'Subscriber Limit Count',
        data: 'subscriber_limit_count',
        width: '150px',
        render: data => styler(150, data ? data : 'N/A'),
      },
      //////////////////////////////////////////////////////
      {
        title: 'Actions',
        data: null,
        width: '92px',
        orderable: false,
        render: function (data) {
          let edit = `<a href="/trigger-manager/${data.id}/edit" class="edit-button" style="cursor: pointer; padding: 2px">
                       <img src="${EditIcon}" alt="Edit" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
                     </a>`;
          let del = `<a class="delete-button" style="cursor: pointer; padding: 2px">
                       <img src="${DeleteIcon}" alt="Delete" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
                     </a>`;

          return `<div style="display: inline-block;">${edit}</div><div style="display: inline-block;">${del}</div>`;
        },
      },
    ],
    [],
  );

  const applyColumnVisibility = (api, storedVisibility) => {
    api
      .columns()
      .indexes()
      .each(index => {
        const colName = api.column(index).header().textContent.trim();
        const isVisible = storedVisibility[colName] !== false;
        api.column(index).visible(isVisible);

        // Update dropdown item background color based on visibility
        const dropdownItem = $(`.dropdown-item:contains("${colName}")`);
        if (isVisible) {
          dropdownItem.removeClass('hidden'); // Remove red background if column is visible
        } else {
          dropdownItem.addClass('hidden'); // Add red background if column is hidden
        }
      });
  };

  const handleColumnVisibilityChange = api => {
    const columnVisibility = api
      .columns()
      .indexes()
      .reduce((acc, colIndex) => {
        const colName = api.column(colIndex).header().textContent.trim();
        acc[colName] = api.column(colIndex).visible();
        return acc;
      }, {});

    // Save the updated column visibility to sessionStorage
    sessionStorage.setItem('columns', JSON.stringify(columnVisibility));
  };

  function formatToMMDDYYYY(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;

    hours = hours ? hours : 12;

    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${month}/${day}/${year} ${formattedTime}`;
  }

  const debouncedSearch = value => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout
    const timeout = setTimeout(() => {
      const api = $(tableRef.current).DataTable();
      api.search(value).draw();
    }, 1000);

    setSearchTimeout(timeout);
  };

  const getParamsFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    params.draw = parseInt(urlParams.get('draw')) || 1;
    params.start = parseInt(urlParams.get('start')) || 0;
    params.length = parseInt(urlParams.get('length')) || 25; // Set default length to 25

    const order = urlParams.get('order');
    if (order) {
      params.order = JSON.parse(order);
    }

    const search = urlParams.get('search');
    if (search) {
      params.search = JSON.parse(search);
    }

    return params;
  };

  const fetchData = async params => {
    // Get existing params from the URL
    const urlParams = getParamsFromURL();

    // Merge existing URL params with the new params
    const mergedParams = { ...urlParams, ...params };

    // Serialize the merged params, excluding the 'columns' propertye
    const { columns, ...paramsWithoutColumns } = mergedParams;
    const serializedParams = {};

    for (let key in paramsWithoutColumns) {
      if (paramsWithoutColumns.hasOwnProperty(key)) {
        if (typeof paramsWithoutColumns[key] === 'object') {
          serializedParams[key] = JSON.stringify(paramsWithoutColumns[key]);
        } else {
          serializedParams[key] = paramsWithoutColumns[key];
        }
      }
    }

    const queryString = new URLSearchParams(serializedParams).toString();
    const newUrl = `${window.location.pathname}?${queryString}`;
    window.history.replaceState(null, '', newUrl);
    // 4 & 5 need to be fixed
    if (mergedParams?.order[0]?.column && mergedParams?.order[0]?.column > 3) {
      mergedParams.order[0].column -= 1;
    }

    // Make the POST request with the merged params
    const response = await axios.post(
      `/triggers/datatables?datatables=yes`,
      mergedParams,
    );
    return response;
  };

  const handleDelete = async id => {
    axios
      .put(`/triggers/${id}/archive`)
      .then(pass => {
        setDelPass(true);
      })
      .catch(err => {
        console.error(err);
        setDelFail(true);
      })
      .finally(() => {
        setTimeout(() => {
          setTriggerDel(false);
          setDeleteId(0);
          setDelFail(false);
          setDelPass(false);
        }, 4000);

        window.location.reload();
      });
  };

  const handleClose = () => {
    setDelPass(false);
    setDelFail(false);
  };

  useEffect(() => {
    const initialParams = getParamsFromURL();

    // Check if the 'draw' parameter exists in the URL
    if (!initialParams.draw) {
      // Set the URL to the first draw info if 'draw' is missing
      const initialDrawParams = {
        draw: 1,
        start: 0,
        length: 25, // Set default length to 25
        order: JSON.stringify([[0, 'asc']]),
        search: JSON.stringify({ value: '', regex: false }),
      };
      const queryString = new URLSearchParams(initialDrawParams).toString();
      const newUrl = `${window.location.pathname}?${queryString}`;
      window.history.replaceState(null, '', newUrl);
    }
    const storedVisibility = sessionStorage.getItem('columns');
    const initialVisibility = storedVisibility
      ? JSON.parse(storedVisibility)
      : {};

    if (!$.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable({
        serverSide: true,
        processing: true,
        columns: columns,
        // dom: '<"d-flex justify-content-between"<"date-range-inputs">fBl>rtip',
        dom: '<"d-flex align-items-center dt-controls"lBf>rtip',
        buttons: ['columnsToggle'],
        responsive: true,
        scrollX: tableRef.current ? tableRef.current.offsetWidth : '100%',
        displayStart: initialParams.start || 0, // Set initial start value from URL
        pageLength: initialParams.length || 25, // Set initial length value from URL
        order: initialParams.order || [[0, 'asc']], // Set initial order from URL
        search: initialParams.search || { value: '', regex: false }, // Set initial search from URL
        searchDelay: 1000,
        ajax: (data, callback, settings) => {
          fetchData(data)
            .then(result => {
              callback({
                draw: data.draw,
                recordsTotal: result.recordsTotal || 0,
                recordsFiltered: result.recordsFiltered || 0,
                data: result.data,
              });
            })
            .catch(error => {
              console.error('Error fetching data: ', error);
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            });
        },
        language: {
          infoFiltered: '',
        },
        initComplete: function () {
          const api = this.api();
          applyCustomStyles(api);
          attachEventListeners(api);
          // JSON.parse(sessionStorage.getItem('columns'))
          applyColumnVisibility(api, initialVisibility);

          // Create a dropdown to hold column visibility buttons
          const dropdownHTML = `
            <div class="column-visibility-dropdown">
              <button class="dropdown-btn">Toggle Columns</button>
              <div class="dropdown-content" style="display: none;"> <!-- Initially hidden -->
                ${$(api.table().container())
                  .find('.buttons-columnVisibility')
                  .map(function () {
                    return `<div class="dropdown-item">${$(this).prop(
                      'outerHTML',
                    )}</div>`;
                  })
                  .get()
                  .join('')}
              </div>
            </div>
          `;

          // Append the dropdown to the DataTables container
          $(api.table().container()).find('.dt-controls').append(dropdownHTML);

          // Show the dropdown content when the button is clicked
          $('.dropdown-btn').on('click', function () {
            const $dropdownContent = $(this).next('.dropdown-content');
            $dropdownContent.toggle(); // Toggle the visibility of the dropdown
          });

          // Get all columns and their visibility states
          const columnVisibility = api
            .columns()
            .indexes()
            .toArray()
            .reduce((acc, colIndex) => {
              const colTitle = api.column(colIndex).header().textContent.trim();
              acc[colTitle] = api.column(colIndex).visible();
              return acc;
            }, {});

          // Loop through each column and set the background color of dropdown items
          api
            .columns()
            .indexes()
            .each(function (index) {
              const colName = api.column(index).header().textContent.trim();
              const isVisible = columnVisibility[colName];
              const dropdownItem = $(`.dropdown-item:contains("${colName}")`);

              // Add or remove the 'hidden' class based on column visibility
              if (!isVisible) {
                dropdownItem.addClass('hidden'); // Mark the item as hidden with red background
              } else {
                dropdownItem.removeClass('hidden'); // Remove 'hidden' class if column is visible
              }
            });

          // Event listener for clicking dropdown items to toggle column visibility
          $(document).on('click', '.dropdown-item', function () {
            const colName = $(this).text().trim();
            const colIndex = api
              .columns()
              .indexes()
              .toArray()
              .find(index => {
                return (
                  api.column(index).header().textContent.trim() === colName
                );
              });
            // Toggle the column visibility
            const currentVisibility = api.column(colIndex).visible();
            api.column(colIndex).visible(!currentVisibility); // Toggle visibility

            // Update the dropdown item background color
            const dropdownItem = $(this);
            if (currentVisibility) {
              dropdownItem.addClass('hidden'); // If column was visible, mark it as hidden (red background)
            } else {
              dropdownItem.removeClass('hidden'); // If column was hidden, remove the red background
            }

            // Update column visibility state in sessionStorage
            handleColumnVisibilityChange(api);
            applyColumnVisibility(
              api,
              JSON.parse(sessionStorage.getItem('columns')),
            );
            applyCustomStyles(api);
          });

          // Bind the search input to the DataTables search functionality
          const searchBox = $('input[type="search"]');
          searchBox.on('keyup', function () {
            debouncedSearch(this.value); // Call debounced search
          });

          // Ensure search param is correctly set on initialization
          if (initialParams.search && initialParams.search.value) {
            api.search(initialParams.search.value).draw();
          }
          applyCustomStyles(api);
        },

        drawCallback: function (settings) {
          const api = this.api();
          const draw = settings.iDraw || initialParams.draw; // Preserve the current draw number
          const start = settings._iDisplayStart; // Get the current start value
          const length = settings._iDisplayLength; // Get the current length value
          const order = api.order(); // Get the current order
          const search = api.search(); // Get the current search value
          attachEventListeners(api);
          applyCustomStyles(api);

          // Update the URL with the current state of DataTable
          const queryString = new URLSearchParams({
            draw: draw,
            start: start,
            length: length,
            order: JSON.stringify(order),
            search: JSON.stringify({ value: search, regex: false }), // Sync search term to URL
          }).toString();

          const newUrl = `${window.location.pathname}?${queryString}`;
          window.history.replaceState(null, '', newUrl);

          api
            .columns()
            .indexes()
            .each(function (index) {
              const colName = api.column(index).header().textContent.trim();
              const isVisible = api.column(index).visible();
              const dropdownItem = $(`.dropdown-item:contains("${colName}")`);

              // Add or remove the 'hidden' class based on visibility
              if (!isVisible) {
                dropdownItem.addClass('hidden'); // Mark the item as hidden
              } else {
                dropdownItem.removeClass('hidden'); // Mark the item as visible
              }
            });

          api
            .rows({ page: 'current' })
            .every(function (rowIdx, tableLoop, rowLoop) {
              const rowData = this.data();
              const chartId = `chart-${rowData.id}`; // Match chart ID in render
              const canvas = document.getElementById(chartId);

              if (canvas && rowData.status_codes) {
                // Group data by status_code
                const groupedData = rowData.status_codes.reduce((acc, item) => {
                  const { date, count, status_code } = item;
                  if (!acc[status_code]) {
                    acc[status_code] = [];
                  }
                  acc[status_code].push({ date, count });
                  return acc;
                }, {});

                // Extract unique dates for the x-axis
                const allDates = Array.from(
                  new Set(rowData.status_codes.map(item => item.date)),
                ).sort();

                // Create datasets for each status_code
                const datasets = Object.entries(groupedData).map(
                  ([status_code, data]) => {
                    const counts = allDates.map(date => {
                      const entry = data.find(item => item.date === date);
                      return entry ? entry.count : 0; // Default to 0 if no data for the date
                    });

                    return {
                      label: `Status Code: ${status_code}`,
                      data: counts,
                      borderColor: colors[status_code] || '#000000', // Use status code color
                      backgroundColor: 'transparent', // Transparent background
                      borderWidth: 2, // Line thickness
                      tension: 0,
                      pointRadius: 0, // Remove point display
                      pointHoverRadius: 0, // Remove hover points
                    };
                  },
                );

                // Destroy any existing chart on the canvas
                Chart.getChart(chartId)?.destroy();

                // Initialize a new Chart.js instance
                new Chart(canvas, {
                  type: 'line', // Line chart
                  data: {
                    labels: allDates, // X-axis labels (dates)
                    datasets: datasets, // Each dataset corresponds to a status code
                  },
                  options: {
                    responsive: true,
                    maintainAspectRatio: false, // Fit within the table cell
                    plugins: {
                      legend: { display: false }, // Hide legend
                      tooltip: {
                        intersect: false,
                        enabled: true, // Enable tooltips
                        callbacks: {
                          label: function (context) {
                            const value = context.raw; // Y-axis value
                            const label = context.dataset.label; // Dataset label (Status Code)
                            return `${label}: ${value} records`;
                          },
                        },
                        displayColors: true, // Show color boxes in tooltips
                      },
                    },
                    interaction: {
                      mode: 'index', // Tooltip activation mode
                      axis: 'x', // Trigger tooltips on the X-axis
                      intersect: false, // Show tooltips even when not directly over a point
                    },
                    scales: {
                      x: {
                        display: false, // Hide the x-axis
                      },
                      y: {
                        display: false, // Hide the y-axis
                        beginAtZero: true, // Ensure the y-axis starts at 0
                      },
                    },
                  },
                });
              }
            });
        },
      });
    }

    function applyCustomStyles(api) {
      // Continue applying other custom styles
      $(api.table().container()).find('table').css({
        'table-layout': 'fixed',
      });
      $(api.table().container()).find('.dt-buttons').css({
        display: 'none',
      });
      $(api.table().container())
        .find('label, input, select, th, td, .dt-info')
        .css({
          'font-size': '14px',
          'font-weight': 'bold',
        });
      $(api.table().container()).find('label').css({
        padding: '5px',
      });
      $(api.table().container()).find('input, select').css({
        margin: '10px',
      });
      $(api.table().container()).find('thead tr th').css({
        'padding-left': '10px',
        'text-align': 'left',
        'border-bottom': '2px solid #dee2e6',
        'border-right': '1px solid #dee2e6',
      });
      $(api.table().container()).find('tfoot tr th').css({
        'border-bottom': '2px solid #dee2e6',
      });
      $(api.table().container()).find('.dt-info').css({
        'padding-left': '10px',
      });
      $(api.table().container()).find('tbody tr td').css({
        'padding-left': '10px',
        'border-bottom': '1px solid #dee2e6',
        'border-right': '1px solid #dee2e6',
        'white-space': 'normal', // Change to normal to wrap text
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
      });
      $(api.table().container())
        .find('tbody tr td:last-child, thead tr th:last-child')
        .css({
          'border-right': 'none',
        });
      $(api.table().container())
        .find(
          '.DTFC_LeftWrapper table th, .DTFC_LeftWrapper table td, .DTFC_Cloned th, .DTFC_Cloned td',
        )
        .css({
          'border-right': '1px solid #dee2e6',
          'background-color': 'inherit',
        });
      $(api.table().container())
        .find(
          '.DTFC_LeftWrapper table th:last-child, .DTFC_LeftWrapper table td:last-child, .DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child',
        )
        .css({
          'border-right': 'none',
        });
      $(api.table().container())
        .find('.DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child')
        .css({
          'box-shadow': '10px 0 5px -2px rgba(0, 0, 0, 0.3)',
        });

      $(api.table().container()).find('ul.pagination').css({
        display: 'flex', // Use flexbox to align items horizontally
        justifyContent: 'center', // Center align pagination controls
        padding: '0', // Remove default padding
        marginTop: '10px', // Add some spacing above the pagination controls
        listStyle: 'none', // Remove default list styling
      });

      $(api.table().container()).find('ul.pagination li').css({
        display: 'inline',
        margin: '0 2px', // Add spacing between pagination items
      });

      $(api.table().container()).find('ul.pagination li a').css({
        display: 'inline-block', // Ensure links are inline
        padding: '5px 10px', // Add padding for clickable area
        border: '1px solid #ddd', // Border for pagination items
        borderRadius: '5px', // Rounded corners for pagination items
        textDecoration: 'none', // Remove underline from links
        color: '#333', // Text color
      });

      $(api.table().container()).find('ul.pagination li.active a').css({
        backgroundColor: '#E00001', // Highlight background color for the active page
        color: 'white', // Text color for active page
      });

      $(api.table().container()).find('ul.pagination li a:hover').css({
        backgroundColor: '#f1f1f1', // Background color on hover
        color: '#333', // Text color on hover
      });

      // Apply Flexbox styling to the parent container of "entries per page" and "Search"
      $(api.table().container()).find('.dt-controls').css({
        position: 'sticky', // Make it sticky
        top: '0', // Stick it to the top
        left: '250px', // Align it to the left of the viewport
        zIndex: '1000', // Ensure it stays above other content
        padding: '10px 15px', // Add some padding to the controls
        display: 'flex', // Use flexbox for alignment
        justifyContent: 'space-between', // Align items to the left
        alignItems: 'center', // Center vertically
        // width: '40%', // Ensure it spans the entire width
        // width: 'calc(95vw - 250px)', // Ensure it spans from 250px to the right end of the viewport
        // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Optional shadow
      });

      // Increase size for all inputs with ids matching dt-search-*
      $(api.table().container()).find('input[id^="dt-search-"]').css({
        height: '2.5rem',
        width: '15rem',
      });

      // Increase size for all selects with ids matching dt-length-*
      $(api.table().container()).find('select[id^="dt-length-"]').css({
        height: '2.5rem',
        width: '3rem',
      });

      // Apply flexbox to div.dt-length
      $(api.table().container()).find('div.dt-length').css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      $(api.table().container()).find('div.dt-search').css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      $(api.table().container()).find('.buttons-html5, .buttons-print').css({
        'background-color': '#E00001',
        'border-width': '2px',
        'border-radius': '10px',
        'border-color': '#E00001',
        color: 'white',
        padding: '10px',
        cursor: 'pointer', // Add this line to change the cursor on hover
      });

      // New dropdown styles
      $(api.table().container()).find('.column-visibility-dropdown').css({
        position: 'relative',
        display: 'inline-block',
      });

      $(api.table().container()).find('.dropdown-btn').css({
        backgroundColor: '#e00001',
        color: 'white',
        padding: '10px',
        fontSize: '14px',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '4px',
        marginBottom: '5px',
      });

      $(api.table().container()).find('.dropdown-content').css({
        // display: 'none',
        position: 'absolute',
        backgroundColor: '#f9f9f9',
        minWidth: '160px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        zIndex: '1',
        padding: '8px 0',
        maxWidth: 'calc(100% - 20px)', // Ensure dropdown stays within viewport
        overflow: 'visible', // Make sure it expands without clipping
        right: '1px', // Shift the dropdown a little more to the right
      });

      $(api.table().container()).find('.dropdown-content .dropdown-item').css({
        padding: '8px 16px',
        cursor: 'pointer',
        borderBottom: '1px solid #ddd',
      });

      $(api.table().container())
        .find('.dropdown-content .dropdown-item:hover')
        .css({
          backgroundColor: '#ddd',
        });

      $(api.table().container())
        .find('.dropdown-content .dropdown-item.active')
        .css({
          color: '#000000',
          backgroundColor: '#000000',
        });

      $(api.table().container())
        .find('button.dt-button.buttons-columnVisibility.dt-button-active')
        .css({
          color: '#000000',
          border: 'none',
          textDecoration: 'none',
          fontWeight: '700',
        });

      $(api.table().container())
        .find('button.dt-button.buttons-columnVisibility')
        .css({
          backgroundColor: 'transparent',
          color: '#000',
          textDecoration: 'none',
        });

      $(api.table().container())
        .find('.dropdown-content .dropdown-item.hidden')
        .css({
          backgroundColor: '#f44336',
          color: '#ffffff',
        });
    }

    function attachEventListeners(api) {
      $('.delete-button')
        .off('click')
        .on('click', function () {
          const row = $(this).closest('tr');
          const rowData = api.row(row).data();

          setTriggerDel(true);
          setDeleteId(rowData.id);
        });

      // $('.contacts-button')
      //   .off('click')
      //   .on('click', function () {
      //     const row = $(this).closest('tr');
      //     const rowData = api.row(row).data();

      //     setContactModal(true);
      //     setContactID(rowData.id);
      //   });
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [columns]);

  return (
    <>
      <Snackbar
        open={delPass}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#4caf50',
          }}
          message={<Typography>Successfully Deleted Trigger</Typography>}
          action={<CloseIcon fontSize="small" onClick={handleClose} />}
        />
      </Snackbar>
      <Snackbar
        open={delFail}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#E00001',
          }}
          message={<Typography>Could not Delete Trigger</Typography>}
          action={<CloseIcon fontSize="small" onClick={handleClose} />}
        />
      </Snackbar>
      {triggerDel ? (
        <CallBack id={deleteId} cbNo={setTriggerDel} cbYes={handleDelete} />
      ) : null}
      <Box>
        <table
          ref={tableRef}
          className="display"
          style={{ width: '100%' }}
        ></table>
      </Box>
      {isLoading && (
        <Box display="flex" paddingTop={2} justifyContent="center">
          <Spinner />
        </Box>
      )}
    </>
  );
};

export default TriggerList;
