import React from 'react';
import './styles.css';

// eslint-disable-next-line react/prop-types
const CallBack = ({ id, cbYes, cbNo }) => {
  return (
    <div className="popup">
      <section className="popup__content">
        <div id="popup__content__close"></div>
        <div className="popup__content__options">
          <h3>Delete Pixel</h3>
          <p>Are you sure you want to delete this pixel?</p>
          <div className="popup__content__button">
            <button id="popup__content__button--no" onClick={() => cbNo(false)}>
              No
            </button>
            <button id="popup__content__button--yes" onClick={() => cbYes(id)}>
              Yes
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CallBack;
