/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// React
import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
// Datatables
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css'; // DataTables Buttons Bootstrap 4 CSS
import 'datatables.net-bs4';
import CallBack from './callback';
// MUI
import Box from '@material-ui/core/Box';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
// Components
import Spinner from '../../components/Spinner';
import DeleteIcon from './delete.svg';
import EditIcon from './edit.svg';
import CodeIcon from './code.svg';
import '../../dtButtons.css';
import Chart from 'chart.js/auto';

const PixelList = ({
  rows,
  isLoading,
  totalPages,
  totalElements,
  onQueryChange,
  onPixelCodeGenerate,
}) => {
  const tableRef = useRef(null);
  const [pixelDel, setPixelDel] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [delPass, setDelPass] = useState(false);
  const [delFail, setDelFail] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);

  const colors = {
    0: '#000000', // Black
    3: '#FFFF00', // Yellow
    200: '#0000FF', // Blue
    201: '#008000', // Green
    202: '#008080', // Teal
    203: '#800080', // Purple
    204: '#A52A2A', // Brown
    408: '#FFA500', // Orange
    500: '#E00001', // Red
  };

  const columns = useMemo(
    () => [
      { title: 'ID', data: 'id', width: '50px' },
      { title: 'Name', data: 'pixel_name', width: '290px' },
      { title: 'Description', data: 'description' },
      { title: 'Total Opens', data: 'fires_number', width: '200px' },
      /*
      {
        title: 'Chart',
        data: null,
        width: '200px',
        orderable: false,
        render: function (data, type, row) {
          return `<div style="width:225px; height:100px;">PLACEHOLDER</div>`;
        },
        // render: function (data, type, row) {
        //   const chartId = `chart-${row.id}`;
        //   return `<div class="chart-details" style="width:225px; height:100px;"><canvas id="${chartId}"></canvas></div>`;
        // },
      },
      */
      { title: 'Trigger', data: 'trigger_name', width: '200px' },
      {
        title: 'Actions',
        data: 'null',
        width: '250px',
        orderable: false,
        render: function (data, type, row) {
          // Uncomment and customize the following actions as needed:
          let edit = `<a href="/pixel/${row.id}/edit" class="edit-button" style="cursor: pointer; padding: 2px">
                        <img src="${EditIcon}" alt="Edit" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
                      </a>`;
          let del = `<a class="delete-button" style="cursor: pointer; padding: 2px">
                        <img src="${DeleteIcon}" alt="Delete" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
                      </a>`;

          let code = `<a class="code-button" style="cursor: pointer; padding: 2px">
                        <img src="${CodeIcon}" alt="Code" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
                      </a>`;

          return `<div style="display: inline-block;">${code}</div>
                  <div style="display: inline-block;">${edit}</div>
                  <div style="display: inline-block;">${del}</div>`;
        },
      },
    ],
    [],
  );

  const getParamsFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    params.draw = parseInt(urlParams.get('draw')) || 1;
    params.start = parseInt(urlParams.get('start')) || 0;
    params.length = parseInt(urlParams.get('length')) || 25; // Set default length to 25

    const order = urlParams.get('order');
    if (order) {
      params.order = JSON.parse(order);
    }

    const search = urlParams.get('search');
    if (search) {
      params.search = JSON.parse(search);
    }

    return params;
  };

  const fetchData = async params => {
    // Get existing params from the URL
    const urlParams = getParamsFromURL();

    // Merge existing URL params with the new params
    const mergedParams = { ...urlParams, ...params };

    // Serialize the merged params, excluding the 'columns' property
    const { columns, ...paramsWithoutColumns } = mergedParams;
    const serializedParams = {};

    for (let key in paramsWithoutColumns) {
      if (paramsWithoutColumns.hasOwnProperty(key)) {
        if (typeof paramsWithoutColumns[key] === 'object') {
          serializedParams[key] = JSON.stringify(paramsWithoutColumns[key]);
        } else {
          serializedParams[key] = paramsWithoutColumns[key];
        }
      }
    }

    const queryString = new URLSearchParams(serializedParams).toString();
    const newUrl = `${window.location.pathname}?${queryString}`;
    window.history.replaceState(null, '', newUrl);
    // if (mergedParams?.order[0]?.column && mergedParams?.order[0]?.column > 3) {
    //   mergedParams.order[0].column -= 1;
    // }

    // Make the POST request with the merged params
    const response = await axios.post(
      `/reports/incomingNotifications/?datatables=pixel-management`,
      mergedParams,
    );
    return response;
  };

  function formatToMMDDYYYY(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;

    hours = hours ? hours : 12;

    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${month}/${day}/${year} ${formattedTime}`;
  }

  const handleDelete = async id => {
    axios
      .put(`/pixels/${id}/archive`)
      .then(pass => {
        setDelPass(true);
      })
      .catch(err => {
        console.error(err);
        setDelFail(true);
      })
      .finally(() => {
        setTimeout(() => {
          setPixelDel(false);
          setDeleteId(0);
          setDelFail(false);
          setDelPass(false);
        }, 4000);

        window.location.reload();
      });
  };

  const handleClose = () => {
    setDelPass(false);
    setDelFail(false);
  };

  const debouncedSearch = value => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout
    const timeout = setTimeout(() => {
      const api = $(tableRef.current).DataTable();
      api.search(value).draw();
    }, 750);

    setSearchTimeout(timeout);
  };

  useEffect(() => {
    const initialParams = getParamsFromURL();

    // Check if the 'draw' parameter exists in the URL
    if (!initialParams.draw) {
      // Set the URL to the first draw info if 'draw' is missing
      const initialDrawParams = {
        draw: 1,
        start: 0,
        length: 25, // Set default length to 25
        order: JSON.stringify([[0, 'asc']]),
        search: JSON.stringify({ value: '', regex: false }),
      };
      const queryString = new URLSearchParams(initialDrawParams).toString();
      const newUrl = `${window.location.pathname}?${queryString}`;
      window.history.replaceState(null, '', newUrl);
    }

    if (!$.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable({
        serverSide: true,
        processing: true,
        columns: columns,
        searchDelay: 750,
        // dom: '<"d-flex justify-content-between"<"date-range-inputs">fBl>rtip',
        dom: '<"d-flex align-items-center dt-controls"lf>rtip',
        responsive: true,
        scrollX: true,
        displayStart: initialParams.start || 0, // Set initial start value from URL
        pageLength: initialParams.length || 25, // Set initial length value from URL
        order: initialParams.order || [[0, 'asc']], // Set initial order from URL
        search: initialParams.search || { value: '', regex: false }, // Set initial search from URL
        ajax: (data, callback, settings) => {
          fetchData(data)
            .then(result => {
              callback({
                draw: data.draw,
                recordsTotal: result.recordsTotal || 0,
                recordsFiltered: result.recordsFiltered || 0,
                data: result.data,
              });
            })
            .catch(error => {
              console.error('Error fetching data: ', error);
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            });
        },
        language: {
          infoFiltered: '',
        },
        initComplete: function () {
          const api = this.api();
          applyCustomStyles(api);
          attachEventListeners(api);

          // Bind the search input to the DataTables search functionality
          const searchBox = $('input[type="search"]');
          searchBox.on('keyup', function () {
            debouncedSearch(this.value); // Call debounced search
          });

          // Ensure search param is correctly set on initialization
          if (initialParams.search && initialParams.search.value) {
            api.search(initialParams.search.value).draw();
          }
        },
        drawCallback: function (settings) {
          const api = this.api();
          const draw = settings.iDraw || initialParams.draw; // Preserve the current draw number
          const start = settings._iDisplayStart; // Get the current start value
          const length = settings._iDisplayLength; // Get the current length value
          const order = api.order(); // Get the current order
          const search = api.search(); // Get the current search value
          attachEventListeners(api);

          // Update the URL with the current state of DataTable
          const queryString = new URLSearchParams({
            draw: draw,
            start: start,
            length: length,
            order: JSON.stringify(order),
            search: JSON.stringify({ value: search, regex: false }), // Sync search term to URL
          }).toString();

          const newUrl = `${window.location.pathname}?${queryString}`;
          window.history.replaceState(null, '', newUrl);
          applyCustomStyles(api);

          api
            .rows({ page: 'current' })
            .every(function (rowIdx, tableLoop, rowLoop) {
              const rowData = this.data();
              const chartId = `chart-${rowData.id}`; // Match chart ID in render
              const canvas = document.getElementById(chartId);

              if (canvas && rowData.hourly_data) {
                // Ensure 28 data points in hourly_data
                const expectedLength = 28;
                const currentLength = rowData.hourly_data.length;

                if (currentLength < expectedLength) {
                  const firstEntry = rowData.hourly_data[0];
                  const firstDate = new Date(
                    firstEntry?.date_created || new Date(),
                  );
                  const firstValue = firstEntry?.hourly_records || 0;

                  const missingData = [];

                  for (let i = 0; i < expectedLength - currentLength; i++) {
                    const missingDate = new Date(firstDate);
                    missingDate.setHours(
                      firstDate.getHours() - (i + 1) * 6,
                      0,
                      0,
                      0,
                    ); // Subtract 6 hours for each missing entry

                    const formattedDate = missingDate
                      .toISOString()
                      .slice(0, 19)
                      .replace('T', ' ');

                    missingData.unshift({
                      date_created: formattedDate,
                      hourly_records: firstValue, // Use the first value to fill
                    });
                  }

                  rowData.hourly_data = [
                    ...missingData,
                    ...rowData.hourly_data,
                  ]; // Prepend missing data
                }

                // Add the current timestamp and list_count as the last data point
                // const now = new Date();
                // const formattedNow = now
                //   .toISOString()
                //   .slice(0, 19)
                //   .replace('T', ' ');

                const formattedNow = rowData.current_db_time;
                const recordCount = parseInt(
                  rowData.list_count.split(',').join(''),
                  10,
                );

                rowData.hourly_data.push({
                  date_created: formattedNow,
                  hourly_records: recordCount,
                });

                // Extract X and Y axis data from hourly_data
                const labels = rowData.hourly_data.map(entry =>
                  entry.date_created.slice(0, 19),
                ); // Trim timestamp
                const dataPoints = rowData.hourly_data.map(
                  entry => entry.hourly_records,
                );

                // Calculate min and max values for the Y-axis
                const maxValue =
                  Math.max(...dataPoints) < 1000
                    ? Math.max(...dataPoints) * 3
                    : Math.max(...dataPoints) + 1; // Conditional max value
                const minValue = Math.min(...dataPoints) - 1; // Add buffer below

                if (maxValue === 0) {
                  canvas.style.display = 'none'; // Hide the canvas if maxValue is 0
                  return;
                }

                // Destroy any existing chart on the canvas
                Chart.getChart(chartId)?.destroy();

                // Initialize a new Chart.js instance
                new Chart(canvas, {
                  type: 'line', // Use line chart
                  data: {
                    labels: labels, // X-axis labels (timestamps)
                    datasets: [
                      {
                        label: 'Hourly Records',
                        data: dataPoints, // Y-axis data
                        borderColor: '#E00001', // Line color
                        backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
                        tension: 0.4, // Smooth curves for the line
                        borderWidth: 2, // Thickness of the line
                        pointRadius: 0, // Disable point display (no bubbles)
                      },
                    ],
                  },
                  options: {
                    responsive: true,
                    maintainAspectRatio: false, // Allow the chart to fit within the table cell
                    plugins: {
                      legend: { display: false }, // Hide the legend
                      tooltip: {
                        enabled: true, // Ensure tooltips are enabled
                        callbacks: {
                          label: function (context) {
                            const value = context.raw; // Y-axis value
                            return `${value} records`;
                          },
                        },
                        displayColors: false, // Removes the color box from the tooltip
                      },
                    },
                    interaction: {
                      mode: 'nearest', // Tooltip activation mode
                      axis: 'x', // Trigger tooltips on the X-axis
                      intersect: false, // Show tooltips even when not directly over a point
                    },
                    scales: {
                      x: {
                        display: false, // Completely hide the X-axis
                      },
                      y: {
                        display: false, // Completely hide the Y-axis
                        suggestedMin: minValue, // Dynamically set minimum value
                        suggestedMax: maxValue, // Dynamically set maximum value
                      },
                    },
                    layout: {
                      padding: {
                        top: 10,
                        bottom: 10,
                        right: 5,
                      },
                    },
                    elements: {
                      line: {
                        tension: 0.4, // Smooth curves
                        borderWidth: 2, // Line thickness
                      },
                    },
                  },
                });
              }
            });
        },
      });
    }

    function applyCustomStyles(api) {
      // Continue applying other custom styles
      $(api.table().container())
        .find('label, input, select, th, td, .dt-info')
        .css({
          'font-size': '14px',
          'font-weight': 'bold',
        });
      $(api.table().container()).find('label').css({
        padding: '5px',
      });
      $(api.table().container()).find('input, select').css({
        margin: '10px',
      });
      $(api.table().container()).find('thead tr th').css({
        'padding-left': '10px',
        'text-align': 'left',
        'border-bottom': '2px solid #dee2e6',
        'border-right': '1px solid #dee2e6',
      });
      $(api.table().container()).find('tfoot tr th').css({
        'border-bottom': '2px solid #dee2e6',
      });
      $(api.table().container()).find('.dt-info').css({
        'padding-left': '10px',
      });
      $(api.table().container()).find('tbody tr td').css({
        'padding-left': '10px',
        'border-bottom': '1px solid #dee2e6',
        'border-right': '1px solid #dee2e6',
        'white-space': 'normal', // Change to normal to wrap text
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
      });
      $(api.table().container())
        .find('tbody tr td:last-child, thead tr th:last-child')
        .css({
          'border-right': 'none',
        });
      $(api.table().container())
        .find(
          '.DTFC_LeftWrapper table th, .DTFC_LeftWrapper table td, .DTFC_Cloned th, .DTFC_Cloned td',
        )
        .css({
          'border-right': '1px solid #dee2e6',
          'background-color': 'inherit',
        });
      $(api.table().container())
        .find(
          '.DTFC_LeftWrapper table th:last-child, .DTFC_LeftWrapper table td:last-child, .DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child',
        )
        .css({
          'border-right': 'none',
        });
      $(api.table().container())
        .find('.DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child')
        .css({
          'box-shadow': '10px 0 5px -2px rgba(0, 0, 0, 0.3)',
        });

      $(api.table().container()).find('ul.pagination').css({
        display: 'flex', // Use flexbox to align items horizontally
        justifyContent: 'center', // Center align pagination controls
        padding: '0', // Remove default padding
        marginTop: '10px', // Add some spacing above the pagination controls
        listStyle: 'none', // Remove default list styling
      });

      $(api.table().container()).find('ul.pagination li').css({
        display: 'inline',
        margin: '0 2px', // Add spacing between pagination items
      });

      $(api.table().container()).find('ul.pagination li a').css({
        display: 'inline-block', // Ensure links are inline
        padding: '5px 10px', // Add padding for clickable area
        border: '1px solid #ddd', // Border for pagination items
        borderRadius: '5px', // Rounded corners for pagination items
        textDecoration: 'none', // Remove underline from links
        color: '#333', // Text color
      });

      $(api.table().container()).find('ul.pagination li.active a').css({
        backgroundColor: '#E00001', // Highlight background color for the active page
        color: 'white', // Text color for active page
      });

      $(api.table().container()).find('ul.pagination li a:hover').css({
        backgroundColor: '#f1f1f1', // Background color on hover
        color: '#333', // Text color on hover
      });

      // Apply Flexbox styling to the parent container of "entries per page" and "Search"
      $(api.table().container()).find('.dt-controls').css({
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%', // Ensure it fills the entire width of the container
      });

      // Increase size for all inputs with ids matching dt-search-*
      $(api.table().container()).find('input[id^="dt-search-"]').css({
        height: '2.5rem',
        width: '15rem',
      });

      // Increase size for all selects with ids matching dt-length-*
      $(api.table().container()).find('select[id^="dt-length-"]').css({
        height: '2.5rem',
        width: '3rem',
      });

      // Apply flexbox to div.dt-length
      $(api.table().container()).find('div.dt-length').css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      $(api.table().container()).find('div.dt-search').css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      $(api.table().container()).find('.buttons-html5, .buttons-print').css({
        'background-color': '#E00001',
        'border-width': '2px',
        'border-radius': '10px',
        'border-color': '#E00001',
        color: 'white',
        padding: '10px',
        cursor: 'pointer', // Add this line to change the cursor on hover
      });
    }

    function attachEventListeners(api) {
      $('.delete-button')
        .off('click')
        .on('click', function () {
          const row = $(this).closest('tr');
          const rowData = api.row(row).data();

          setPixelDel(true);
          setDeleteId(rowData.id);
        });

      $('.code-button')
        .off('click')
        .on('click', function () {
          const row = $(this).closest('tr');
          const rowData = api.row(row).data();

          onPixelCodeGenerate(rowData);
        });

      // $('.contacts-button')
      //   .off('click')
      //   .on('click', function () {
      //     const row = $(this).closest('tr');
      //     const rowData = api.row(row).data();

      //     setContactModal(true);
      //     setContactID(rowData.id);
      //   });
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [columns]);

  return (
    <>
      <Snackbar
        open={delPass}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#4caf50',
          }}
          message={<Typography>Successfully Deleted Trigger</Typography>}
          action={<CloseIcon fontSize="small" onClick={handleClose} />}
        />
      </Snackbar>
      <Snackbar
        open={delFail}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#E00001',
          }}
          message={<Typography>Could not Delete Trigger</Typography>}
          action={<CloseIcon fontSize="small" onClick={handleClose} />}
        />
      </Snackbar>
      {pixelDel ? (
        <CallBack id={deleteId} cbNo={setPixelDel} cbYes={handleDelete} />
      ) : null}
      <Box>
        <table
          ref={tableRef}
          className="display"
          style={{ width: '100%' }}
        ></table>
      </Box>
      {isLoading && (
        <Box display="flex" paddingTop={2} justifyContent="center">
          <Spinner />
        </Box>
      )}
    </>
  );
};

export default PixelList;

// import axios from 'axios';
// import { useMemo } from 'react';
// import Link from '@material-ui/core/Link';
// import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
// import CodeOffOutlinedIcon from '@material-ui/icons/CodeOffOutlined';
// import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

// import DataGrid from '../../components/DataGrid';
// import RowAction from '../../components/RowAction';
// import RowActions from '../../components/RowActions';
// import RouterLink from '../../components/RouterLink';

// const columns = [
//   {
//     field: 'name',
//     title: 'Name',
//     width: '290px',
//   },
//   {
//     field: 'description',
//     title: 'Description',
//   },
//   {
//     field: 'numberOfFires',
//     title: 'Total opens',
//     width: '200px',
//     align: 'center',
//   },
//   {
//     field: 'trigger',
//     title: 'Trigger',
//     width: '200px',
//   },
//   {
//     field: 'actions',
//     title: '',
//     width: '156px',
//   },
// ];

// const PixelList = ({
//   rows,
//   isLoading,
//   totalPages,
//   totalElements,
//   onQueryChange,
//   onPixelCodeGenerate,
// }) => {
//   const dataSource = useMemo(
//     () =>
//       rows.map(row => ({
//         id: row.id,
//         name: row.pixel_name,
//         description: row.description,
//         numberOfFires: row.fires_number,
//         trigger: row.trigger_id ? (
//           <Link
//             color="inherit"
//             component={RouterLink}
//             to={`/trigger-manager/${row.trigger_id}/edit`}
//           >
//             {row.trigger_name}
//           </Link>
//         ) : (
//           `-`
//         ),
//         actions: (
//           <RowActions
//             actions={[
//               {
//                 title: 'Copy Pixel Code',
//                 children: <CodeOffOutlinedIcon />,
//                 onClick: () => onPixelCodeGenerate(row),
//               },
//               {
//                 title: 'Edit',
//                 component: RouterLink,
//                 to: `/pixel/${row.id}/edit`,
//                 children: <BorderColorOutlinedIcon />,
//               },
//               {
//                 title: 'Delete',
//                 children: (
//                   <RowAction
//                     icon={<DeleteOutlinedIcon />}
//                     buttonLabel="Delete"
//                     actionLabel="delete"
//                     entityLabel="pixel"
//                     queryKey="pixelData"
//                     queryFunction={() => axios.put(`/pixels/${row.id}/archive`)}
//                   />
//                 ),
//               },
//             ]}
//           />
//         ),
//       })),
//     [rows, onPixelCodeGenerate],
//   );

//   return (
//     <DataGrid
//       pageable
//       rowKey="id"
//       rows={dataSource}
//       columns={columns}
//       loading={isLoading}
//       totalPages={totalPages}
//       totalElements={totalElements}
//       onQueryChange={onQueryChange}
//       emptyText="You don't have any pixels yet"
//     />
//   );
// };

// export default PixelList;
